import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAdmin,
  getAllUser,
  getMainCategory,
  getMasterCategory,
  getSizeLabel,
  getSubCategory,
} from "./GeneralApi";

const generalCategorySlice = createSlice({
  name: "generalCategory",
  initialState: {
    masterCategories: [],
    sizeLabels: [],
    mainCategories: [],
    subCategories: [],
    allAdmin: [],
    allUser: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMasterCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.masterCategories = action.payload;
    });
    builder.addCase(getMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMainCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.mainCategories = action.payload;
    });
    builder.addCase(getMainCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.subCategories = action.payload;
    });
    builder.addCase(getSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getSizeLabel.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSizeLabel.fulfilled, (state, action) => {
      state.loading = false;
      state.sizeLabels = action.payload;
    });
    builder.addCase(getSizeLabel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getAllAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.allAdmin = action.payload;
    });
    builder.addCase(getAllAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getAllUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllUser.fulfilled, (state, action) => {
      state.loading = false;
      state.allUser = action.payload;
    });
    builder.addCase(getAllUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default generalCategorySlice.reducer;
