// src/config.js

// const config = {
// BASE_URL: process.env.REACT_APP_BASE_URL_LOCAL,
// S3: {
//     ACCESS_KEY_ID: process.env.REACT_APP_BASE_URL_LOCAL_ACCESS_KEY_ID,
//     SECRET_ACCESS_KEY: process.env.REACT_APP_BASE_URL_LOCAL_SECRET_ACCESS_KEY,
//     REGION: process.env.REACT_APP_BASE_URL_LOCAL_REGION,
//     BUCKET: process.env.REACT_APP_BASE_URL_LOCAL_BUCKET,
// },
// };

// export default config;

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const API_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL;
const API_URL_PROD = process.env.REACT_APP_BASE_URL_PROD;

const SOCKET_URL_LOCAL = process.env.REACT_APP_ONLINE_USER_URL_LOCAL;
const SOCKET_URL_PROD = process.env.REACT_APP_ONLINE_USER_URL_PROD;

const config = {
  base: {
    url: API_URL_LOCAL,
  },
  ws: {
    url: SOCKET_URL_LOCAL,
  },
};
//CHECK FOR ENVIRONMENT
if (ENVIRONMENT === "prod") {
  config.base.url = API_URL_PROD;
  config.ws.url = SOCKET_URL_PROD;
} else {
  config.base.url = API_URL_LOCAL;
  config.ws.url = SOCKET_URL_LOCAL;
}

export { config, ENVIRONMENT };
