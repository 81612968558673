import { Navigate } from "react-router-dom";
import useAuthToken from "../Hook/AuthToken/authToken";

const PrivateRoute = ({ children }) => {
  const isTokenPresent = useAuthToken();

  // Redirect to login if no token is present
  return isTokenPresent ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
