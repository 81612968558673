import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

function ErrorPage() {
  return (
    <div className="h-screen flex items-center p-5 lg:p-20 overflow-hidden ">
      <div className="flex-1 x rounded-3xl relative md:flex items-center text-center md:text-left">
        <>
          <main className=" w-full flex flex-col justify-center items-center ">
            <div className="mb-5font-bold rounded-md ">
              <img src={logo} alt="logo" className="h-16" />
            </div>
            <h1 className="text-9xl font-extrabold text-primary tracking-widest">
              404
            </h1>
            <div className="bg-white text-primary px-2 text-sm rounded rotate-12 absolute top-32">
              Page Not Found
            </div>
            <button className="mt-5">
              <Link
                to="/"
                className="relative inline-block text-sm font-medium text-white group  focus:outline-none focus:ring"
              >
                <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-primary group-hover:translate-y-0 group-hover:translate-x-0" />
                <span className="relative block px-8 py-3 bg-primary border border-current">
                  Go Home
                </span>
              </Link>
            </button>
          </main>
        </>
      </div>
    </div>
  );
}

export default ErrorPage;
