import { configureStore } from "@reduxjs/toolkit";
import masterCategoryReducer from "../features/General/GeneralSlice";
import productReducer from "../features/Product/productSlice";
import chatReducer from "../features/Chat/chatSlice";

export const store = configureStore({
  reducer: {
    generalCategory: masterCategoryReducer,
    product: productReducer,
    chat: chatReducer,
  },
});
