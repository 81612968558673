import React from "react";

const Loader = () => {
  return (
    <>
      <div className="fixed w-full bg-opacity-40 backdrop-blur-sm  h-modal h-full animated fadeInDown  bg-gray-600 overflow-auto z-[9999999] inset-0">
        <div className="h-screen flex justify-center items-center">
          <div className="grid grid-cols-3 gap-2">
            <div className="w-4 h-4 bg-[#03577A] animate-pulse"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-200"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-400"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-600"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-800"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-1000"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-1200"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-1400"></div>
            <div className="w-4 h-4 bg-[#03577A] animate-pulse delay-1600"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
