import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstanceJson from "../../../Api/AxiosInstanceJson";

export const starMessage = createAsyncThunk(
  "chat/starMessage",
  async ({ messageId }, thunkAPI) => {
    try {
      const response = await axiosInstanceJson.post(
        `/v1/admin/chat/star`,
        {
          _id: messageId,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const unStarMessage = createAsyncThunk(
  "chat/unStarMessage",
  async ({ messageId }, thunkAPI) => {
    try {
      const response = await axiosInstanceJson.post(
        `/v1/admin/chat/unstar`,
        {
          _id: messageId,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

