import { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./layouts/Sidebar.js";
import Router from "./Routes/router.js";
import useAuthToken from "./Hook/AuthToken/authToken.js";
import Loader from "./Loader/Loader.js";

function App() {
  useAuthToken();

  const location = useLocation();
  const { pathname } = location;
  const shouldRenderNavbar = ["/login"].includes(pathname);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [pathname]);

  return (
    <div className="flex h-full">
      {!shouldRenderNavbar && (
        <div className="flex">
          <div>
            <SideBar />
          </div>
        </div>
      )}

      <div className="w-full h-screen overflow-y-auto">
        {showLoader ? (
          <Loader />
        ) : (
          <Suspense fallback={<Loader />}>
            <Router />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default App;
