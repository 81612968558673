import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Api/AxiosInstance";
import axiosInstanceJson from "../../../Api/AxiosInstanceJson";

// POST PRODUCT DATA
export const postProduct = createAsyncThunk(
  "product/post",
  async (productdata, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.post(
        `/v1/vendor/product/post-product`,
        productdata
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET PRODUCT DATA FILTER WISE
export const getProduct = createAsyncThunk(
  "product/getAll",
  async (
    {
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
      vendorId,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/product/get-product`,
        {
          params: {
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
            vendorId,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL PRODUCT
export const getAllProduct = createAsyncThunk(
  "getAllProduct",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/vendor/product/get-product`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET PRODUCT BY ID
export const getProductById = createAsyncThunk(
  "product/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/product/get-singleProduct/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UPDATE PRODUCT DATA
export const updateProduct = createAsyncThunk(
  "product/update",
  async (productData, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/vendor/product/update-product/${productData._id}`,
        productData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE PRODUCT DATA
export const softDeleteProduct = createAsyncThunk(
  "product/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/vendor/product/soft-delete-product/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE PRODUCT DATA
export const undoDeleteProduct = createAsyncThunk(
  "product/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/vendor/product/undo-delete-product/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// MULTIPLE DELETE PRODUCT DATA
export const softDeleteProducts = createAsyncThunk(
  "product/softDeleteProducts",
  async (ids, thunkAPI) => {
    try {
      await axiosInstanceJson.put(`/v1/vendor/product/delete-selected-data`, {
        ids,
      });
      return ids;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
